'use client'

import React from 'react'
import { ErrorPage, StatusCodes } from 'components/error-page/error-page'

type ErrorProps = {
	error: Error & { statusCode?: StatusCodes }
}

const Error = ({ error }: ErrorProps) => {
	const statusCode = error?.statusCode || 404

	return <ErrorPage statusCode={statusCode} error={error} />
}

export default Error
